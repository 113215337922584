.searchContainer{
    width: 85%;
    background-color: white;
    border-radius: 1.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    margin: 2rem 0;
    .searchIcon{
        margin: 0 1.5rem;
    }
    input{
        width: 100%;
        text-decoration: none;
        -webkit-appearance: none;
        border: 0;
        outline: none;
        font-size: 1rem;
        &focus{
            outline: none;
        }
    }
}