.filterContainer{
    display: flex;
    width: 90%;
    justify-content: space-around;
    .active{
        font-family: ArchivoBold;
        font-size: 1.5rem;
        border-bottom: 3px solid #05A0A8;
    }
    .inactive{
        font-family: ArchivoBold;
        color: #B5B5B5;
        font-size: 1.5rem;
    }
}