.masonryContainer{
  width: 95%;
  margin-top: 2rem;
}
.myMasonryGrid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.myMasonryGridColumn {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;

}

// /* Style your items */
// .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
//   background: grey;
//   margin-bottom: 30px;
// }
.personas{
  font-size: 2rem;
  font-family: ArchivoBold;
  margin-left: 10px;

}