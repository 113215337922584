.cardContainer{
    // background-color: white;
    height: 12rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 20px;
    border-radius: 20px 20px 0 0; 
    .bottomCard{
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        height: 3rem;
        background-color: black;
        color: white;
        text-transform: capitalize;
        .mesa{
            display: flex;
            flex-direction: column;
            margin: 0;
            h4{
                margin: 0;
            }
            p{
                margin: 0;
                font-size: .5rem;
            }
        }
        .icon{
            width: 25px;
            height: auto;
        }
    }
}