.container{
    background-color: #F5F6FA;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        font-family: ArchivoBold;
        font-size: 2rem;
    }
}